import Vue from 'vue'
import VueRouter from 'vue-router'
import { channelAvailable, productLists } from '@/api/user'
const _ = require('lodash')

// 避免重复导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/goToBorrowMoney'
  },
  // {
  //   path: '/login/:channelCode?',
  //   name: 'login',
  //   component: () => import('@/views/LoginView')
  // },
  {
    path: '/login/:channelCode?',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/skeleton',
    name: 'skeleton',
    redirect: 'goToBorrowMoney',
    component: () => import('@/views/SkeletonView'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomeView')
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('@/views/MyView')
      }
    ]
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/complete/MainInfo')
  },
  {
    // 《注册协议》
    path: '/registerAgreement',
    name: 'registerAgreement',
    component: () => import('@/views/RegisterAgreementView')
  },
  {
    // 《隐私协议》
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views//PrivacyView')
  },
  {
    // 《个人信息授权书》
    path: '/dkzqs',
    name: 'dkzqs',
    component: () => import('@/views/DkzqsView')
  },
  {
    // 《风险提示告知书》
    path: '/mianze',
    name: 'mianze',
    component: () => import('@/views/MianzeView')
  },
  {
    path: '/clickhistory',
    name: 'clickhistory',
    component: () => import('@/views/ClickHistoryView')
  },
  {
    path: '/push',
    name: 'push',
    component: () => import('@/views/PushView')
  },
  {
    path: '/stop',
    name: 'stop',
    component: () => import('@/views/stop')
  },
  // {
  //   path: '/cityInformation',
  //   name: 'cityInformation',
  //   component: () => import('@/views/complete/cityInformation')
  // },
  {
    path: '/cityInformation',
    name: 'cityInformation',
    component: () => import('@/views/complete/newCityInformation')
  },
  // 5秒倒计时页面
  // {
  //   path: '/loanApplication',
  //   name: 'loanApplication',
  //   component: () => import('@/views/complete/loanApplication')
  // },
  {
    path: '/loanApplication',
    name: 'loanApplication',
    component: () => import('@/views/complete/newLoanApplication')
  },

  // 初审通过页面
  {
    path: '/preliminaryReviewPassed',
    name: 'preliminaryReviewPassed',
    component: () => import('@/views/complete/preliminaryReviewPassed')
  },
  // 申请成功
  {
    path: '/applicationSuccessful',
    name: 'applicationSuccessful',
    component: () => import('@/views/complete/applicationSuccessful')
  },
  // 申请借款
  {
    path: '/goToBorrowMoney',
    name: 'goToBorrowMoney',
    component: () => import('@/views/complete/goToBorrowMoney')
  },

  // {
  //   path: '/newMainInfo',
  //   name: 'newMainInfo',
  //   component: () => import('@/views/complete/newMainInfo')
  // },
  {
    path: '/newMainInfo',
    name: 'newMainInfo',
    component: () => import('@/views/complete/estimate')
  },
  {
    // 《个人身份信息处理授权书》
    path: '/identityInformation',
    name: 'identityInformation',
    component: () => import('@/views/agreement/IdentityInformation')
  },
  {
    // 《个人信息处理授权书》
    path: '/personalInformation',
    name: 'personalInformation',
    component: () => import('@/views/agreement/PersonalInformation')
  },
  {
    // 《第三方SDK说明》
    path: '/sdkIllustrate',
    name: 'sdkIllustrate',
    component: () => import('@/views/agreement/sdkIllustrate')
  },
  {
    // 《人脸核身个人信息处理授权协议》
    path: '/facialInformation',
    name: 'facialInformation',
    component: () => import('@/views/agreement/facialInformation')
  },
  {
    // 《个人信息共享清单》
    path: '/sharedInventory',
    name: 'sharedInventory',
    component: () => import('@/views/agreement/sharedInventory')
  },
  {
    // 《个人信息共享授权书》
    path: '/sharingAuthorizationLetter',
    name: 'sharingAuthorizationLetter',
    component: () => import('@/views/agreement/sharingAuthorizationLetter')
  },
  {
    path: '/downloadApp',
    name: 'downloadApp',
    component: () => import('@/views/downloadApp')
  },
  // 模拟半流程的授权回调页
  {
    path: '/callback/result',
    name: 'callbackResult',
    component: () => import('@/views/callback/result')
  },
  // 半流程着陆页
  {
    path: '/half/result',
    name: 'halfResult',
    component: () => import('@/views/half/result')
  },
  // 半流程结果页
  {
    path: '/half/success',
    name: 'halfSuccess',
    component: () => import('@/views/half/success')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (
    [
      'login',
      'identityInformation',
      'personalInformation',
      'sdkIllustrate',
      'facialInformation',
      'sharedInventory',
      'registerAgreement',
      'privacy',
      'dkzqs',
      'mianze',
      'stop',
      'downloadApp',
      'sharingAuthorizationLetter',
      'callback/result',
      'halfResult',
      'halfSuccess'
    ].indexOf(to.name) >= 0
  ) {
    const cc = to.query.channelCode || localStorage.getItem('channelCode')
    if (cc) {
      localStorage.setItem('channelCode', cc)
    }
    next()
  } else {
    const cc = to.query.channelCode || localStorage.getItem('channelCode')
    if (cc) {
      localStorage.setItem('channelCode', cc)
    }
    const token = localStorage.getItem('token')
    // console.log('token', token)
    const channelCode = localStorage.getItem('channelCode') || 'default'
    // console.log(channelCode)
    // return
    if (to.name !== 'login' && !token) {
      router.push('/login?channelCode=' + channelCode)
      next()
    } else {
      if (channelCode === 'default') {
        await getProductList()
        next()
      } else {
        const caRes = await channelAvailable()
        if (caRes.code === 1000 || !caRes.data.isAvailable) {
          router.push('/stop')
        } else {
          localStorage.setItem('isResultRedirect', caRes.data.isResultRedirect)
          localStorage.setItem(
            'isHomepageRedirect',
            caRes.data.isHomepageRedirect
          )

          await getProductList()
          next()
        }
      }
    }
  }
})

// 获取产品列表
const getProductList = async () => {
  const currentTime = _.now()
  const lastVisitTime = localStorage.getItem('lastVisitTime')
  const productList = localStorage.getItem('productLists')

  if (
    (lastVisitTime && currentTime - lastVisitTime > 3600000) ||
    !lastVisitTime ||
    productList === '[]'
  ) {
    const res = await productLists()
    if (res.code === 200 && res.data) {
      const lastVisitTime = _.now()
      localStorage.setItem('lastVisitTime', lastVisitTime)
      localStorage.setItem('productLists', JSON.stringify(res.data))
    }
  }
}

export default router
